import React, { useEffect, useState, useRef } from 'react';
import { usePagination } from '../../../utils/HooksUtils';
// import FiltersWrapper from 'components/FiltersWrapper';
// import Card from '../../Components/EventCard';
import InformationCard from '../../Components/InformationCard';
import Card from '../../Components/EventCard';
// import InformationCard from '../../Components/InformationCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faAngleRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectList from 'components/select-list';
import { useHistory, useParams } from 'react-router-dom';
import {
  useSportEventBasicInfo,
  useSportEventList,
  useSportEventListDirect,
  // useRankingTableList,
  // useWinnerTable,
  useParticipantList,
  useRulesAndPrice,
  // useTournamentDetails,
  useDrawFilters,
  // useGalleryBySports,
} from '../../queries/hooks';

import ListLoader from 'components/ListLoader';
import HorizontalInformationCard from '../../Components/HorizontalInformationCard';
import Table from 'micro-site/Components/Table';
// import RecentWinnersCarousel from 'micro-site/Components/RecentWinnersCarousel';
// import MobileRankingTable from 'micro-site/Components/MobileRankingTable';
import ResultCard from 'micro-site/Components/ResultCard';
// import WinnerCard from 'micro-site/Components/WinnerCard';
// import Loader from 'components/Loader';
import FilterIcon from '../../../assets/img/Filters.svg';
import { useShowDigitalScoring } from '../../../profiles/queries/profileHooks';
// import Draws from '../DrawsAndSchedule/ExpandedMobileView';
// import DetailItemLoader from 'components/DetailItemLoader';
// import Header from 'micro-site/Components/Header';
// import microSiteLogo from '../../../assets/img/logo/uttarakhand_logo.jpg';
// import microSiteBg from '../../../assets/img/uttarakhand_desktop.jpg';
import Loader from 'components/Loader';
import { useShowKhelo, useInfiniteScroll } from 'utils/HooksUtils';
// import { useUserProfile } from 'profiles/queries/profileHooks';
// import Helmet from 'react-helmet';
// import { getDateInMonthDateYear, getTimeIn12HourFormat } from 'constants/DateFunctions';
// import SearchBar from '../../Components/SearchBar';
// import SectionSliderMesh from '../Gallery/components/SectionSliderMesh';
import {
  drawsText,
  // rankingRecentText, galleryText,
  filtersText,
  rulesText,
} from 'data/noDataText';
// import Helmet from 'react-helmet';
// import { getDateInMonthDateYear, getTimeIn12HourFormat } from 'constants/DateFunctions';
// import { GrandTotalDesktop, GrandTotalMobile } from '../Results/components/MainPage';

// import { tournament } from 'constants/DrawEventForm/index';

export default function index() {
  const isKhelo = useShowKhelo();
  const history = useHistory();
  const params = useParams();
  const [data, setData] = useState(null);
  // const [ageGroupList, setAgeGroupList] = useState([]);
  // const [ageGroup, setAgeGroup] = useState(null);
  const [genderList, setGenderList] = useState([]);
  const [gender, setGender] = useState(null);
  const [drawsage, setDrawsage] = useState(null);
  const [drawsgender, setDrawsgender] = useState(null);
  const [drawsGenderList, setDrawsGenderList] = useState([]);
  const [drawsAgeList, setDrawsAgeList] = useState([]);
  const [filterPopup, setFilterPopup] = useState(false);
  // const [expandedDraws, setExpandedDraws] = useState(null);
  // const [opponent, setOpponent] = useState('opponent_1');
  const infiniteDrawsMobileRef = useRef(null);
  // const userData = useUserProfile();
  const digitalScoringQuery = useShowDigitalScoring({
    tournamentId: params?.tournament_id,
  });
  const DrawFilters = useDrawFilters({
    tournamentId: params?.tournament_id,
    sport: params.sport,
  });
  const rulesAndPrice = useRulesAndPrice({
    tournamentId: params?.tournament_id,
    sport: params.sport,
  });

  const sportEventBasicInfoQuery = useSportEventBasicInfo({
    tournament: params?.tournament_id,
    sport: params.sport,
  });

  const sportEventListQuery = useSportEventList({
    tournament: params?.tournament_id,
    sport: params.sport,
    // age_group: ageGroup ? (ageGroup === 'All' ? '' : ageGroup) : '',
    age_group: '',
    gender: gender ? (gender === 'All' ? '' : gender) : '',
    date: '',
    fees_range: '',
  });
  // const tournamentDetailsQuery = useTournamentDetails({
  //   tournament_id: location.pathname.split('/')[2],
  // });

  const {
    data: pagination,
    setPage,
    currentPage: page,
    maxPage,
  } = usePagination(sportEventListQuery.data);

  const sportEventListBackUpQuery = useSportEventListDirect({
    tournament: params?.tournament_id,
    sport: params.sport,
  });

  useEffect(() => {
    if (sportEventListBackUpQuery.isFetched) {
      setData(sportEventListBackUpQuery?.data);
    }
  }, [sportEventListBackUpQuery.isFetched]);

  // const getAgeGroupList = () => {
  //   const ageGroupList = [...new Set(data?.map((el) => el.age_group))];
  //   setAgeGroupList(['All', ...ageGroupList.sort()]);
  // };

  const getGenderList = () => {
    const genderList = [...new Set(data?.map((el) => el.gender))];
    setGenderList(['All', ...genderList.sort()]);
  };
  useEffect(() => {
    if (sportEventListBackUpQuery.isFetched) {
      // getAgeGroupList();
      getGenderList();
    }
  }, [data]);

  // const sportEventPriceQuery = useSportEventPriceDetails({
  //   tournament: location.pathname.split('/')[2],
  //   sport: params.sport,
  // });
  // const filterData = [
  //   {
  //     type: 'dropdown',
  //     selectedValue: ageGroup,
  //     setSelectedValue: setAgeGroup,
  //     placeholder: 'Age Group',
  //     listData: ageGroupList,
  //     dataCy: 'age_group',
  //   },
  //   {
  //     type: 'dropdown',
  //     selectedValue: gender,
  //     setSelectedValue: setGender,
  //     placeholder: 'Gender',
  //     listData: genderList,
  //     dataCy: 'gender',
  //   },
  // ];

  // const rankingTableListFullQueryData = useRankingTableList({
  //   tournament: location.pathname.split('/')[2],
  //   preview: true,
  //   sport: params.sport,
  //   event: '',
  //   medal: '',
  //   round: '',
  //   limit: 30,
  // });

  // const columnsTable = [
  //   {
  //     key: 'angle_bottom',
  //     label: '',
  //     component: 'AngleBottomColumn',
  //   },
  //   {
  //     key: 'rank',
  //     label: 'Rank',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'institution',
  //     label: 'School Name',
  //     component: 'TextAndImage',
  //   },
  //   {
  //     key: 'gold',
  //     label: 'Gold',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'silver',
  //     label: 'Silver',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'bronze',
  //     label: 'Bronze',
  //     component: 'TextColumn',
  //   },
  //   {
  //     key: 'points',
  //     label: 'Points',
  //     component: 'TextColumn',
  //   },
  // ];

  // const winnerTableQueryData = useWinnerTable({
  //   tournament: location.pathname.split('/')[2],
  //   recent: 'True',
  //   sport: params.sport,
  // });

  const participantListQueryData = useParticipantList({
    tournament: params.tournament_id,
    date: '',
    sport: params.sport,
    round: '',
    gender: drawsgender ? (drawsgender === 'All' ? '' : drawsgender) : '',
    age_group: drawsage ? (drawsage === 'All' ? '' : drawsage) : '',
    limit: 25,
    search: '',
  });

  // const galleryBySports = useGalleryBySports({
  //   sport: params.sport,
  // });

  useInfiniteScroll(
    infiniteDrawsMobileRef,
    participantListQueryData.fetchNextPage,
    participantListQueryData.isFetchingNextPage || participantListQueryData.isFetching,
    true
  );

  useEffect(() => {
    participantListQueryData.refetch();
  }, [drawsgender, drawsage]);

  useEffect(() => {
    if (DrawFilters.isFetched && DrawFilters?.data?.message) {
      setDrawsGenderList(['All', ...DrawFilters?.data?.message?.genders]);
      setDrawsAgeList(['All', ...DrawFilters?.data?.message?.age_groups]);
    }
  }, [DrawFilters.isFetched, DrawFilters.data]);

  const clearFilterHandler = () => {
    setDrawsgender('All');
    setDrawsage('All');
    setFilterPopup(false);
  };

  const filterPopupHandler = () => {
    setFilterPopup(!filterPopup);
  };

  // const drawsFilterData = [
  //   {
  //     type: 'dropdown',
  //     selectedValue: drawsgender,
  //     setSelectedValue: setDrawsgender,
  //     placeholder: 'Gender',
  //     listData: drawsGenderList,
  //   },
  //   {
  //     type: 'dropdown',
  //     selectedValue: drawsage,
  //     setSelectedValue: setDrawsage,
  //     placeholder: 'Age',
  //     listData: drawsAgeList,
  //   },
  // ];

  const drawAndScheduleColumn = [
    {
      key: 'sport',
      label: 'Sport',
      component: 'SportColumn',
      showSubCategory: true,
    },
    {
      key: 'match',
      label: 'Round',
      component: 'RoundColumn',
    },
    {
      key: 'venue',
      label: 'Venue',
      component: 'VenueColumn',
    },
    {
      key: 'time',
      label: 'Date & Time',
      component: 'DateTimeColumn',
    },
    {
      key: 'opponent_1',
      label: 'Opponent 1',
      component: 'OpponentColumn',
    },
    {
      key: 'opponent_2',
      label: 'Opponent 2',
      component: 'OpponentColumn',
    },
    {
      key: 'score',
      label: 'Score',
      component: 'ScoreColumn',
      status: true,
      enableLink: true,
      tournamentId: history.location.pathname.split('/')[2],
    },
  ];

  // if (!expandedDraws)
  return (
    <>
      <div className="bg-white w-full">
        {/* <Helmet>
          <title>List of Sports | KIYG Haryana 2021</title>
          <meta
            name="description"
            content="Here's a list of the 25 Sports, including 5 Indigenous Sports that will take place across 8 venues at the Khelo India Youth Games Haryana 2021"
          />
          <meta property="og:title" content="List of Sports | KIYG Haryana 2021" />
          <meta
            property="og:description"
            content="Here's a list of the 25 Sports, including 5 Indigenous Sports that will take place across 8 venues at the Khelo India Youth Games Haryana 2021"
          />
        </Helmet> */}
        {/* <DetailItemLoader queryData={tournamentDetailsQuery} queryKey="id">
        {({ data }) => {
          return (
            <Header
              eventName={data?.name}
              city={data?.city}
              eventImage={microSiteLogo}
              banner={microSiteBg}
              // price={data.price}
              remarks={`This is one time tournament fees`}
              // remarks={data.remarks}
              startDate={data?.start_date}
              userData={userData}
              endDate={data?.end_date}
            />
          );
        }}
      </DetailItemLoader> */}
        <div className="md:px-16 px-4 md:pt-20 md:py-8 bg-white gap-2 items-center font-roboto max-w-fhd mx-auto">
          {sportEventBasicInfoQuery.isLoading ? null : (
            <div
              className="flex items-center cursor-pointer"
              onClick={() => history.push(`/micro-site/${params?.tournament_id}/landing/sports`)}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="fa-xs text-gray-750 mr-3" />
              <p className="text-gray-750 font-bold my-3 md:my-0">Sports List</p>
            </div>
          )}
          <ListLoader
            queryData={sportEventBasicInfoQuery}
            CustomLoader={null}
            queryKey="title"
            className={`${sportEventBasicInfoQuery.isLoading ? 'h-40' : null}`}
          >
            {({ item, index }) => {
              return (
                <HorizontalInformationCard
                  key={index}
                  index={index}
                  len={sportEventBasicInfoQuery.data.length}
                  title={item.title}
                  content={item.content}
                />
              );
            }}
          </ListLoader>
          {/* <div className="mb-3  mt-4">
          </ListLoader> */}
          <div className="mb-3  mt-4">
            <div className={'md:mb-9 mb-4'}>
              <h1 className="text-lg md:text-4xl font-bold text-blue-kheloBlue font-poppins">Events</h1>
              <div className={'w-16 h-0.5 mt-2 md:mt-3 md:h-2 bg-blue-kheloBlue'} />
            </div>
            <div className={'flex justify-between'}>
              {sportEventListQuery.isLoading && <Loader />}
              {sportEventListQuery?.data?.length > 0 ? (
                // <FiltersWrapper filters={filterData} />
                <SelectList
                  listData={genderList}
                  type="dropdown"
                  selectedValue={gender}
                  setSelectedValue={setGender}
                  placeholder="Gender"
                  dataCy="gender"
                />
              ) : null}
              {/* <SearchBar width={'w-1/6'} title={'search Events'} /> */}
            </div>
            {sportEventListQuery.data ? (
              sportEventListQuery.data.length !== 0 ? (
                <>
                  <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 mt-6 md:mt-10 gap-3">
                    {pagination?.length > 0 ? (
                      pagination.map((item, index) => {
                        return (
                          <div key={index}>
                            <Card
                              data={item}
                              variant="subCategory"
                              canShowDraws={digitalScoringQuery?.data}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="flex items-center justify-end gap-4 mt-6 text-gray-750 font-semibold">
                    {page < maxPage ? (
                      <button
                        disabled={page + 1 === pagination.length}
                        className={`mx-2 cursor-pointer text-black font-semibold  px-4 py-1 rounded flex gap-2 items-center`}
                        onClick={() => {
                          if (page < sportEventListQuery?.data?.length && maxPage) {
                            setPage((el) => el + 1);
                          }
                        }}
                      >
                        <p>View More Events</p>{' '}
                        <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                      </button>
                    ) : null}
                    {page !== 1 ? (
                      <button
                        disabled={page === 0}
                        className={`mx-2 cursor-pointer text-black font-semibold  px-4 py-1 rounded flex gap-2 items-center`}
                        onClick={() => {
                          setPage(1);
                        }}
                      >
                        <p>View Less Events</p>
                        <FontAwesomeIcon icon={faAngleRight} className="mx-1" />
                      </button>
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="mt-4">{filtersText}</div>
              )
            ) : null}
          </div>

          <div className=" flex justify-between items-center md:mb-9 mb-4">
            <div className={''}>
              <h1
                className={`md:text-4xl text-lg font-bold font-poppins ${
                  isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                }`}
              >
                Draws and Schedules
              </h1>
              <div
                className={`w-16 h-0.5 mt-2 md:mt-3 md:h-2 ${
                  isKhelo
                    ? 'bg-blue-kheloBlue text-blue-kheloBlue'
                    : 'bg-purple-500 text-purple-500'
                }`}
              />
            </div>
            {/* <div
              className={`flex gap-2 items-center cursor-pointer`}
              onClick={() => {
                history.push(
                  `/micro-site/${location.pathname.split('/')[2]}/landing/draws-schedules`
                );
              }}
            >
              <p
                className={`${
                  isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                } md:text-lg font-bold text-base`}
              >
                View Full Table
              </p>
              <FontAwesomeIcon
                icon={faAngleRight}
                className={`${isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'} w-4 h-4`}
              />
            </div> */}
          </div>
          <div className="md:flex hidden justify-between">
            <div className="flex gap-2.5">
              {participantListQueryData?.data?.length > 0 ? (
                <div className="w-40 flex">
                  {/* <FiltersWrapper filters={drawsFilterData} /> */}
                  <SelectList
                    type="dropdown"
                    selectedValue={drawsgender}
                    setSelectedValue={setDrawsgender}
                    placeholder="Gender"
                    listData={drawsGenderList}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {participantListQueryData?.data?.pages?.[0]?.data?.length > 0 ? (
            <>
              <div className="mt-4 overflow-y-auto hidden md:block">
                <Table
                  isInfinite
                  isInfiniteFetching={participantListQueryData.isFetchingNextPage}
                  fetchNext={participantListQueryData?.fetchNextPage}
                  columns={drawAndScheduleColumn}
                  rows={participantListQueryData?.data}
                  tableHeight={'500px'}
                />
              </div>
              <div className="mt-4 result_card_container block md:hidden">
                <div className="md:hidden">
                  <div className="mb-3 items-center md:hidden flex justify-end">
                    <div className="flex gap-1">
                      <div onClick={filterPopupHandler} className="flex cursor-pointer items-end">
                        <img
                          src={FilterIcon}
                          className="cursor-pointer w-5 h-5"
                          alt="filter icon"
                        />
                        <p className="text-gray-450 text-sm">Filters</p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="result_card_container overflow-y-auto"
                    style={{ maxHeight: '500px' }}
                    ref={infiniteDrawsMobileRef}
                  >
                    <ListLoader
                      queryData={participantListQueryData}
                      queryKey="result"
                      isInfinite
                      isInfiniteFetching={participantListQueryData.isFetchingNextPage}
                    >
                      {({ item, index }) => (
                        <ResultCard
                          key={index}
                          data={item}
                          // onClick={() => {
                          //   if (item.opponent_1.name === 'NA' && item.opponent_2.name === 'NA') {
                          //     return;
                          //   }
                          //   setExpandedDraws(item);
                          // }}

                          enableLinks
                          showCategoryInMiddle
                        />
                      )}
                    </ListLoader>
                  </div>
                </div>
                <div
                  className={`fixed overflow-y-auto shadow-footerPopUp h-3/4 rounded-t-xl w-full bg-white flex flex-col justify-between px-4 py-6 bottom-0 right-0 left-0 z-20 ${
                    filterPopup ? 'block' : 'hidden'
                  } `}
                >
                  <div>
                    <div className="flex justify-between">
                      <h3 className="text-base font-bold gray-text-750">Filters </h3>
                    </div>
                    <div className="mt-8">
                      <div className="mb-8 w-full">
                        <p className="text-sm mb-1 ">Sport</p>
                        <SelectList
                          selectedValue={drawsgender}
                          setSelectedValue={setDrawsgender}
                          placeholder="Gender"
                          listData={drawsGenderList}
                          fullWidth={true}
                          filterClose={true}
                          setFilterPopup={() => {}}
                        />
                        <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">Age</p>
                        <SelectList
                          selectedValue={drawsage}
                          setSelectedValue={setDrawsage}
                          placeholder="Age"
                          listData={drawsAgeList}
                          fullWidth={true}
                          filterClose={true}
                          setFilterPopup={() => {}}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 items-center text-sm md:text-base mt-auto sticky w-full bottom-0 py-2 bg-white">
                    <button
                      onClick={() => setFilterPopup(false)}
                      className="btn btn-primary btn-md btn-block  bg-gray-750 hover:bg-blue-800 flex gap-1 items-center"
                    >
                      <FontAwesomeIcon icon={faCheck} />
                      <p>Apply</p>
                    </button>
                    <button
                      onClick={clearFilterHandler}
                      className="flex gap-1 items-center justify-center cursor-pointer reg-btn-outline-dark mr-1 w-full hover:bg-gray-250 font-semibold rounded-lg mt-2 py-2 text-gray-dark"
                    >
                      <FontAwesomeIcon icon={faTimes} />
                      <p>Clear</p>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${
                isKhelo ? 'bg-blue-kheloBlue400' : 'bg-purple-950'
              } text-white text-center rounded-lg py-4 w-full mb-3 `}
            >
              {drawsText}
            </div>
          )}

          {/* <div className="mt-8 flex justify-between items-center">
            <div className={'md:mb-4'}>
              <h1
                className={`md:text-4xl text-lg font-bold font-poppins ${
                  isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                }`}
              >
                Ranking Table
              </h1>
              <div
                className={`w-16 h-0.5 md:mt-3 md:h-2 ${
                  isKhelo
                    ? 'bg-blue-kheloBlue text-blue-kheloBlue'
                    : 'bg-purple-500 text-purple-500'
                }`}
              />
            </div>
            <div
              className={`flex gap-2 items-center cursor-pointer`}
              onClick={() =>
                history.push(`/micro-site/${location.pathname.split('/')[2]}/landing/results`)
              }
            >
              <p
                className={`${
                  isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                } md:text-lg font-bold text-base`}
              >
                View Full Table
              </p>
              <FontAwesomeIcon
                icon={faAngleRight}
                className={`${isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'} w-4 h-4`}
              />
            </div>
          </div>
          {rankingTableListFullQueryData.isLoading ? <Loader /> : null}
          {console.log('rtd', rankingTableListFullQueryData?.data)}
          {rankingTableListFullQueryData?.data?.pages?.[0]?.data?.length > 0 ? (
            <>
              <div className="mt-4 hidden md:block">
                <h1 className="py-2 text-right">
                  Last Updated On{' '}
                  {getDateInMonthDateYear(rankingTableListFullQueryData.data.pages[0].last_update)}{' '}
                  {getTimeIn12HourFormat(rankingTableListFullQueryData.data.pages[0].last_update)}
                </h1>
                <Table
                  firstRow={<GrandTotalDesktop data={rankingTableListFullQueryData} />}
                  isInfinite
                  isInfiniteFetching={rankingTableListFullQueryData.isFetchingNextPage}
                  columns={columnsTable}
                  rows={rankingTableListFullQueryData.data}
                  tableHeight={'500px'}
                  sport={params.sport}
                  iconAndText={true}
                  icon={true}
                />
              </div>
              <div className="block md:hidden no-scrollbar">
                <MobileRankingTable
                  firstRow={<GrandTotalMobile data={rankingTableListFullQueryData} />}
                  key={index}
                  columns={columnsTable}
                  Tabledata={rankingTableListFullQueryData}
                  hideFilter
                  height={'500px'}
                ></MobileRankingTable>
              </div>
            </>
          ) : (
            <div
              className={`${
                isKhelo ? 'bg-blue-kheloBlue400' : 'bg-purple-950'
              } text-white text-center rounded-lg py-4 w-full`}
            >
              {rankingRecentText}
            </div>
          )} */}

          {/* <div className="flex justify-between items-center mt-8 mb-4">
            <div className={'md:mb-4'}>
              <h1
                className={`md:text-4xl text-lg font-bold font-poppins ${
                  isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                }`}
              >
                Recent Winners
              </h1>
              <div
                className={`w-16 h-0.5 md:mt-3 md:h-2 ${
                  isKhelo
                    ? 'bg-blue-kheloBlue text-blue-kheloBlue'
                    : 'bg-purple-500 text-purple-500'
                }`}
              />
            </div>
            <div
              className={`flex gap-2 items-center cursor-pointer`}
              onClick={() => {
                history.push(`/micro-site/${location.pathname.split('/')[2]}/landing/results`);
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
              }}
            >
              <p
                className={`${
                  isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                } md:text-lg font-bold text-base`}
              >
                Winners
              </p>
              <FontAwesomeIcon
                icon={faAngleRight}
                className={`${isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'} w-4 h-4`}
              />
            </div>
          </div>
          {console.log(winnerTableQueryData)}
          {winnerTableQueryData.data?.pages?.[0]?.data?.length > 0 ? (
            <>
              <div className="hidden md:block">
                <RecentWinnersCarousel ReactQueryObject={winnerTableQueryData} isInfinite />
              </div>
              <div className="block md:hidden">
                {' '}
                <div
                  className={`mt-4 py-2 rounded-lg ${
                    isKhelo ? 'bg-blue-kheloBlue400' : 'bg-blue-dark'
                  } px-4 md:hidden`}
                >
                  <ListLoader
                    queryData={winnerTableQueryData}
                    queryKey="winners"
                    isInfinite
                    isInfiniteFetching={winnerTableQueryData.isFetching}
                  >
                    {({ item, index }) => {
                      return <WinnerCard key={index} data={item} />;
                    }}
                  </ListLoader>
                </div>
              </div>
            </>
          ) : (
            <div
              className={`${
                isKhelo ? 'bg-blue-kheloBlue400' : 'bg-purple-950'
              } text-white text-center rounded-lg py-4 w-full`}
            >
              {rankingRecentText}
            </div>
          )} */}

          {/* {
            <>
              <div className="mt-16 flex justify-between items-center">
                <div className={'md:mb-4'}>
                  <h1
                    className={`md:text-4xl text-lg font-bold font-poppins ${
                      isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                    }`}
                  >
                    Gallery
                  </h1>
                  <div
                    className={`w-16 h-0.5 md:mt-3 md:h-2 ${
                      isKhelo
                        ? 'bg-blue-kheloBlue text-blue-kheloBlue'
                        : 'bg-purple-500 text-purple-500'
                    }`}
                  />
                </div>
                <div
                  className={`flex gap-2 items-center cursor-pointer`}
                  onClick={() =>
                    history.push(`/micro-site/${location.pathname.split('/')[2]}/landing/gallery`)
                  }
                >
                  <p
                    className={`${
                      isKhelo ? 'text-blue-kheloBlue' : 'text-purple-500'
                    } md:text-lg font-bold text-base`}
                  >
                    View Full Gallery
                  </p>
                  <FontAwesomeIcon icon={faAngleRight} className="w-3 h-3" />
                </div>
              </div>

              {Array.isArray(galleryBySports.data) && galleryBySports?.data?.length > 0 ? (
                <SectionSliderMesh
                  sectionName={params.sport}
                  sectionFolders={galleryBySports}
                  redirectTo={`/micro-site/${location.pathname.split('/')[2]}/landing/gallery`}
                  hideSectionName
                  hideViewAll
                  parentClasses="mt-2"
                />
              ) : !galleryBySports.isFetched ? (
                <Loader />
              ) : (
                <div
                  className={`${
                    isKhelo ? 'bg-blue-kheloBlue400' : 'bg-purple-950'
                  } text-white text-center rounded-lg py-4 w-full`}
                >
                  {galleryText + params.sport}
                </div>
              )}
            </>
          } */}

          {rulesAndPrice?.data && !rulesAndPrice?.data?.message?.error ? (
            <ListLoader CustomLoader={null} queryData={rulesAndPrice} queryKey="title">
              {({ item, index }) => {
                return (
                  <InformationCard
                    key={index}
                    index={index}
                    color={`${isKhelo ? 'blue-kheloBlue' : 'purple-500'}`}
                    len={rulesAndPrice.data.length}
                    title={item.title}
                    content={item.content}
                    EmptyMessage={rulesText}
                  />
                );
              }}
            </ListLoader>
          ) : null}
        </div>
      </div>
    </>
  );
  // return (
  //   <Draws
  //     userData={userData}
  //     expandedView={expandedDraws}
  //     setExpandedView={setExpandedDraws}
  //     opponent={opponent}
  //     setOpponent={setOpponent}
  //   />
  // );
}
