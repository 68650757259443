import React from "react";
import Footer from "../../micro-site/Components/footer/Footer";
import Loader from "../Loader";
import useCustomStyling from "../../hooks/useCustomStyling";
import KheloHeader from "micro-site/Components/KheloHeader/KheloHeader";
import { useHomePageLiveMatchVideos } from "../../micro-site/queries/hooks";

const LiveMatchVideos = () => {
  const { fontSize, isMobile } = useCustomStyling();

  const { data: matches = [], isLoading: isMatchesLoading, isError } = useHomePageLiveMatchVideos();

  return (
    <div className="w-screen">
      <KheloHeader />
      <section
        className="w-full flex flex-col"
        style={{ marginTop: 56, marginBottom: 56 }}
        id="live-matches"
      >
        <h3
          className="font-bold italic font-poppins text-center uppercase"
          style={{
            color: "#0D0D0D",
            fontSize: fontSize,
            marginBottom: isMobile ? 32 : 50,
          }}
        >
          Live Matches
        </h3>

        {/* Filters Row */}
      {!isMatchesLoading && (
      <div className="flex flex-wrap items-center gap-4 mb-6 px-3 ml-16">
        <select
          className="border p-2 rounded-md flex-grow"
          style={{ maxWidth: "150px" }}
        >
          <option value="">Select Sport</option>
          <option value="badminton">Badminton</option>
          <option value="football">Football</option>
          <option value="cricket">Cricket</option>
        </select>
        <select
          className="border p-2 rounded-md flex-grow"
          style={{ maxWidth: "150px" }}
        >
          <option value="">Select Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
        <select
          className="border p-2 rounded-md flex-grow"
          style={{ maxWidth: "200px" }}
        >
          <option value="">Select Event Category</option>
          <option value="single">Single</option>
          <option value="double">Double</option>
        </select>
      </div>
      )}

        <div className="w-11/12 m-auto">
          <div
            className="grid grid-cols-1 md:grid-cols-3 justify-center gap-4 p-4 mx-auto"
            style={{ columnGap: 43, rowGap: 60 }}
          >
            {isMatchesLoading && (
              <div
                className="w-full flex items-center justify-center"
                style={{ marginLeft: "360px" }}
              >
                <Loader />
              </div>
            )}

            {isError && <div className="text-center text-red-500">Failed to load live matches.</div>}

            {!isMatchesLoading && matches.length > 0 ? (  
              matches.map((match) => (
                <div key={match.name} className="flex flex-col gap-3">
                  <iframe
                    width="100%"
                    height="250"
                    src={match.link}
                    title={`Live Match: ${match.title}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                    style={{ borderRadius: "25px" }}
                  />
                  <p
                    className="font-poppins font-semibold text-center"
                    style={{
                      fontSize: isMobile ? 12 : 16,
                      color: "#000B27",
                    }}
                  >
                    {match.title}
                  </p>
                </div>
              ))
            ) : (
              // Fallback UI when no matches are available
              !isMatchesLoading && (
                <div className="m-auto text-gray-500 text-center">
                  Live matches are coming soon
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LiveMatchVideos;