import { FaTwitter, FaYoutube, FaInstagram, FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useCustomStyling from 'hooks/useCustomStyling';
import background from '../../../assets/img/cm-trophy-home-page/background.png';
import f2 from '../../../assets/img/cm-trophy-home-page/f2.png';
import image1 from '../../../assets/img/cm-trophy-home-page/image1.png';
import image2 from '../../../assets/img/cm-trophy-home-page/image2.png';
import poweredByLogo from 'assets/img/poweredByLogo.png';

export default function Footer() {
  const { isMobile } = useCustomStyling();
  return (
    <footer
      className="text-white pt-6 relative overflow-hidden font-poppins"
      style={{
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
    >
      <div
        className="grid grid-cols-1 md:grid-cols-2 mx-auto "
        style={{ paddingLeft: isMobile ? 10 : 120 }}
      >
     <div className="w-full">
      <div className="flex items-center mb-4 w-full">
        <Link to="/">
          <div className="flex items-center space-x-4">
            <img
              src={image1}
              alt="Emblem"
              className="rounded-full"
              style={{ width: isMobile ? '60px' : '80px', height: isMobile ? '60px' : '80px' }}
            />
            <img
              src={image2}
              alt="SDAT Logo"
              style={{ width: isMobile ? '60px' : '80px', height: isMobile ? '60px' : '80px' }}
            />
          </div>
            </Link>
          </div>
          <div>
            <h2 className="font-medium my-6 font-poppins">
              Sports Authority of Telangana
            </h2>
            <p className="mb-1 font-poppins text-sm">Gachibowli Stadium,</p>
            <p className="mb-1 font-poppins text-sm">C8WV+GJ4, Mushroom Rock Road, Gachibowli</p>
            <p className="mb-1 font-poppins text-sm">Hyderabad, Telangana 500032</p>
            <div className="flex items-center justify-between mt-6">
              <div>
                <p className="mb-2 font-poppins text-sm">
                  <a
                    href="mailto:sdat@tn.gov.in"
                    className="hover:underline text-gray-30 ignore-style font-poppins font-bold"
                  >
                    cmcup@tn.gov.in
                  </a>
                </p>
                <p>
                  <a
                    href="tel:9514000777"
                    className="hover:underline text-gray-30 ignore-style font-poppins font-bold"
                  >
                    9514000777
                  </a>
                </p>
              </div>
              <div className="flex space-x-4">
                <a
                  href="https://x.com/satg_sports"
                  target="_blank"
                  aria-label="Twitter"
                  className="text-gray-30 ignore-style"
                  rel="noreferrer"
                >
                  <FaTwitter className="text-2xl" />
                </a>
                <a
                  href="https://youtube.com/@sportsauthorityoftelangana?feature=shared"
                  target="_blank"
                  aria-label="YouTube"
                  className="text-gray-30 ignore-style"
                  rel="noreferrer"
                >
                  <FaYoutube className="text-2xl" />
                </a>
                <a
                  href="https://www.instagram.com/sportsauthorityoftelangana?igsh=MWRkcnZ4d3J1bjB3NA=="
                  target="_blank"
                  aria-label="Instagram"
                  className="text-gray-30 ignore-style"
                  rel="noreferrer"
                >
                  <FaInstagram className="text-2xl" />
                </a>
                <a
                  href="https://m.facebook.com/sportsauthorityoftelangana"
                  aria-label="Facebook"
                  target="_blank"
                  className="text-gray-30 ignore-style"
                  rel="noreferrer"
                >
                  <FaFacebook className="text-2xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container flex flex-wrap justify-between" style={{ paddingTop: '70px', height: isMobile ? 400 : 400 }}>
          <div className="w-full hidden">
            <h3 className=" font-semibold mb-4 font-poppins" style={{ fontSize: '24px' }}>
              Links
            </h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  The Ministry of Youth Affairs and Sports
                </a>
              </li>
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  The Sports Authority of India
                </a>
              </li>
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  The Government of Tamil Nadu
                </a>
              </li>
              <li>
                <a
                  href="https://www.sdat.tn.gov.in/"
                  target={'_blank'}
                  className="hover:underline text-gray-30 ignore-style  font-poppins text-sm "
                  rel="noreferrer"
                >
                  Tamil Nadu Physical Education and Sports University
                </a>
              </li>
            </ul>
          </div>
          <div className="absolute right-20" style={{ transform: "scale(0.8)", transformOrigin: "top right" }}>
          <img src={f2} alt="" className="object-cover" />
        </div>
          </div>
        </div>

      <div
        className="grid grid-cols-1 md:grid-cols-2 mx-auto items-center py-3 mt-4 border-t"
        style={{ paddingLeft: isMobile ? 165 : 120, borderColor: 'rgba(255, 255, 255, 0.2)' }}
      >
        <div className="w-96 flex items-center">
          <p className="font-poppins font-light mr-2" style={{ fontSize: '12px' }}>
            Powered by
          </p>
          <img src={poweredByLogo} alt="Powered by SFA" className="h-4" />
        </div>
        <p className={'w-150 font-poppins font-light'} style={{ fontSize: '12px', marginTop: 9 }}>
          Copyright 2024
        </p>
      </div>
    </footer>
  );
}
