import QUERY_KEYS from './queryKeys';

import { useInfiniteQuery, useQuery } from 'react-query';
import {
  getAdvanceDsLeague,
  getAdvanceScoring,
  getAgeGroup,
  getBuilderIoPage,
  getDrawDateList,
  getDrawFormat,
  getDrawsFilter,
  getEventAttractions,
  getEventsByType,
  getEventsType,
  getFolderList,
  getGalleryFolders,
  getGalleryMedia,
  getGallerySections,
  getGender,
  getNews,
  getParticipantList,
  getParticularNews,
  getPointsMedals,
  getRankingFilters,
  getRankingTableList,
  getRules,
  getShowReconciliation,
  getSportCategory,
  getSportEvent,
  getSportEventBasicInfo,
  getSportEventList,
  getSportEventListDirect,
  getSportEventPriceDetails,
  getSportList,
  getSportSchedules,
  getSubCategory,
  getTournamentBasicInfo,
  getTournamentContactDetails,
  getTournamentDetails,
  getTournamentPriceDetails,
  getTournamentSportList,
  getWinnerTable,
  getGalleryBySports,
  getSubCategoryFilters,
  getSportsName,
  getStatesName,
  getParticipantsName,
  getVenues,
  getMascot,
  getAnthem,
  getAboutUs,
  getScheduleBanners,
  getAboutUsHeading,
  getAllTournaments,
  getParticipants,
  getHomePageSportsList, getHomePageNewsList,
  getHomePageLiveMatchVideos
} from 'micro-site/services';

export const useTournamentDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_DETAILS, params.tournament_id],
    () => getTournamentDetails(params),
    {
      retry: false,
    }
  );
};

export const useDrawFormat = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_DRAW_FORMAT,
      params.tournament,
      params?.sport,
      params?.gender,
      params?.age_group,
      params?.sport_category,
      params?.sub_category,
    ],
    () => getDrawFormat(params),
    {
      retry: false,
    }
  );
};

export const useSportList = (params) => {
  return useQuery([...QUERY_KEYS.GET_SPORT_LIST, params.tournament], () => getSportList(params), {
    retry: false,
  });
};

export const useGender = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_GENDER, params.tournament, params?.sport],
    () => getGender(params),
    {
      retry: false,
    }
  );
};

export const useAgeGroup = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_AGE_CATEGORY, params.tournament, params?.sport, params?.gender],
    () => getAgeGroup(params),
    {
      retry: false,
    }
  );
};

export const useSportCategory = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_CATEGORY,
      params.tournament,
      params?.sport,
      params?.gender,
      params?.age_group,
    ],
    () => getSportCategory(params),
    {
      retry: false,
    }
  );
};

export const useSubCategory = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SUB_CATEGORY,
      params.tournament,
      params?.sport,
      params?.gender,
      params?.age_group,
      params?.sport_category,
    ],
    () => getSubCategory(params),
    {
      retry: false,
    }
  );
};

export const useTournamentBasicInfo = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_BASIC_INFO, params.tournament_id],
    () => getTournamentBasicInfo(params),
    {
      retry: false,
    }
  );
};

export const useTournamentContactDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_CONTACT_DETAILS, params.tournament_id],
    () => getTournamentContactDetails(params),
    {
      retry: false,
    }
  );
};

export const useTournamentPriceDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_PRICE_DETAILS, params.tournament_id],
    () => getTournamentPriceDetails(params),
    {
      retry: false,
    }
  );
};

export const useTournamentSportList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_TOURNAMENT_SPORT_LIST, params.tournamentId],
    () => getTournamentSportList(params),
    {
      retry: false,
    }
  );
};

export const useSportEventBasicInfo = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_BASIC_INFO, params.tournament, params.sport],
    () => getSportEventBasicInfo(params),
    {
      retry: false,
    }
  );
};

export const useSportEventListDirect = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_EVENTS_LIST_DIRECT, params.tournament, params.sport],
    () => getSportEventListDirect(params),
    {
      retry: false,
    }
  );
};

export const useSportEventList = (params, options = { enabled: true }) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_EVENTS_LIST,
      params.tournament,
      params.sport,
      params.age_group,
      params.gender,
      params.date,
      params.fees_range,
    ],
    () => getSportEventList(params),
    {
      retry: false,
      ...options,
    }
  );
};

export const useSportEventPriceDetails = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORT_PRICE_DETAILS, params.tournament, params.sport],
    () => getSportEventPriceDetails(params),
    {
      retry: false,
    }
  );
};

export const usePointsMedals = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_POINTS_MEDALS, params.tournament],
    () => getPointsMedals(params),
    {
      retry: false,
    }
  );
};

export const useRankingTableList = (params) => {
  return useInfiniteQuery(
    [
      ...QUERY_KEYS.GET_RANKING_TABLE_LIST,
      params.tournament,
      params.preview,
      params.sport,
      params.event,
      params.round,
      params.medal,
      params.limit,
    ],
    ({ pageParam = 0 }) => getRankingTableList(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages?.length * params?.limit < lastPage?.total_records)
          return pages?.length * params?.limit;
        return undefined;
      },
      retry: false,
    }
  );
};
export const useWinnerTable = (params) => {
  return useInfiniteQuery(
    [
      ...QUERY_KEYS.GET_WINNER_TABLE_LIST,
      params.tournament,
      params.recent,
      params.sport,
      params.offset,
      params.limit,
      params.subSport,
    ],
    ({ pageParam = 0 }) => getWinnerTable(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (params.recent === 'True') return undefined;
        if (pages.length * params?.limit < lastPage?.total_records)
          return pages.length * params?.limit;
        return undefined;
      },
    }
  );
};

export const useParticipantList = (params) => {
  return useInfiniteQuery(
    [
      ...QUERY_KEYS.GET_PARTICIPANT_LIST,
      params.tournament,
      params.date,
      params.sport,
      params.round,
      params.limit,
      params.search,
    ],
    ({ pageParam = 0 }) => getParticipantList(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * params?.limit < lastPage?.total_records)
          return pages.length * params?.limit;
        return undefined;
      },
    }
  );
};

export const useSportEvent = (params) => {
  return useQuery(
    [
      ...QUERY_KEYS.GET_SPORT_EVENT,
      params?.tournament ? params?.tournament : '',
      params?.sport ? params?.sport : '',
      params?.gender ? params?.gender : '',
      params?.age_group ? params?.age_group : '',
      params?.sport_category ? params?.sport_category : '',
      params?.sub_category ? params?.sub_category : '',
    ],

    () => getSportEvent(params),
    {
      retry: false,
    }
  );
};

export const useDrawDateList = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_DRAW_DATE_LIST, params.tournament],
    () => getDrawDateList(params),
    {
      retry: false,
    }
  );
};

export const useBuilderIoPage = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_BUILDERIO_PAGE, params.tournament],
    () => getBuilderIoPage(params),
    {
      retry: false,
    }
  );
};

export const useShowReconciliation = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_SHOW_RECONCILIATION, params.tournament],
    () => getShowReconciliation(params),
    {
      retry: false,
    }
  );
};

export const useRulesAndPrice = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_RULES_PRICE, params.tournamentId, params.sport],
    () => getRules(params),
    { retry: false }
  );
};

export const useEventAttractions = (params) => {
  return useQuery(
    [...QUERY_KEYS.GET_EVENT_ATTRACTIONS, params.tournament_id],
    () => getEventAttractions(params),
    { retry: false }
  );
};

export const useFolderList = (params, enabled = true) => {
  return useQuery(
    [...QUERY_KEYS.GET_FOLDER_LIST, params.section_name],
    () => getFolderList(params),
    { retry: false, enabled }
  );
};

export const useDrawFilters = (params) => {
  return useQuery([...QUERY_KEYS.GET_DRAWS_FILTERS, params.tournamentId, params.date], () =>
    getDrawsFilter(params)
  );
};

export const useRankingFilters = (params) => {
  return useQuery([...QUERY_KEYS.GET_RANKING_FILTERS, params.tournamentId], () =>
    getRankingFilters(params)
  );
};
export const useRankingEventFilters = (params) => {
  return useQuery([...QUERY_KEYS.GET_SUB_CATEGORY_FILTERS, params.sport], () =>
    getSubCategoryFilters(params)
  );
};
export const useGallerySections = (params) => {
  return useQuery([...QUERY_KEYS.GET_GALLERY_SECTIONS, params.tournamentId], () =>
    getGallerySections(params)
  );
};

export const useSportSchedules = ({ tournamentId }) => {
  return useQuery(
    [...QUERY_KEYS.GET_SPORTS_SCHEDULES, tournamentId],
    () => getSportSchedules({ tournamentId }),
    { retry: false }
  );
};

export const useGalleryFolders = (params, options = {}) => {
  return useQuery(
    [...QUERY_KEYS.GET_GALLERY_FOLDERS, params.section],
    () => getGalleryFolders(params),
    {
      ...options,
    }
  );
};
export const useGalleryMedia = (params, options = {}) => {
  return useQuery([...QUERY_KEYS.GET_GALLERY_MEDIA, params.folder], () => getGalleryMedia(params), {
    ...options,
  });
};

export const useGalleryBySports = (params, options = {}) => {
  return useQuery(
    [...QUERY_KEYS.GET_GALLERY_BY_SPORTS, params.sport],
    () => getGalleryBySports(params),
    { ...options }
  );
};

export const useNews = (params) => {
  return useQuery([...QUERY_KEYS.GET_NEWS, params.tournamentId], () => getNews(params));
};

export const useDetailedNews = (params) => {
  return useQuery([...QUERY_KEYS.GET_NEWS, params.newsTitle], () => getParticularNews(params));
};

export const useAdvanceDSLeague = (_params) => {
  return useQuery([...QUERY_KEYS.GET_ADVANCE_LEAGUE_DS], () => getAdvanceDsLeague());
};

export const useDsScoring = (params) => {
  return useQuery([...QUERY_KEYS.GET_ADVANCE_SCORING, params.matchId], () =>
    getAdvanceScoring(params)
  );
};

export const useEventAttractionsType = (params) => {
  return useQuery([...QUERY_KEYS.GET_EVENT_TYPES, params.tournamentId], () =>
    getEventsType(params)
  );
};

export const useEventAttractionsByType = (params) => {
  return useQuery([...QUERY_KEYS.GET_EVENT_ATTRACTIONS, params.type], () =>
    getEventsByType(params)
  );
};

export const useSportsName = (params) => {
  return useQuery([...QUERY_KEYS.GET_SPORTS_NAME], () => getSportsName(params));
};
export const useStatesName = (params) => {
  return useQuery([...QUERY_KEYS.GET_STATES_NAME], () => getStatesName(params));
};

export const useParticipantsName = (params) => {
  return useInfiniteQuery(
    [
      ...QUERY_KEYS.GET_PARTICIPANTS_NAME,
      params?.tournament ? params?.tournament : '',
      params?.sport ? params?.sport : '',
      params?.gender ? params?.gender : '',
      params?.state ? params?.state : '',
      params?.sub_category ? params?.sub_category : '',
      params?.name ? params?.name : '',
      params.limit,
    ],

    ({ pageParam = 0 }) => getParticipantsName(params, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (pages.length * params?.limit < lastPage?.total_records) {
          return pages.length * params?.limit;
        }
        return undefined;
      },
    }
  );
};

export const useVenues = (params) => {
  return useQuery([...QUERY_KEYS.GET_VENUES, params.city, params.tournament, params.venue], () => {
    return getVenues(params);
  });
};
export const useMascot = (params) => {
  return useQuery([...QUERY_KEYS.GET_MASCOT, params.tournament], () => getMascot(params));
};
export const useAnthem = (params) => {
  return useQuery([...QUERY_KEYS.GET_ANTHEM, params.tournament], () => getAnthem(params));
};
export const useAboutUs = (params) => {
  return useQuery([...QUERY_KEYS.GET_ABOUT_US, params.tournament], () => getAboutUs(params));
};
export const useScheduleBanners = (params) => {
  return useQuery([...QUERY_KEYS.GET_SCHEDULE_BANNERS, params.tournament], () =>
    getScheduleBanners(params)
  );
};

export const useAboutUsHeading = (params, isEnabled) => {
  return useQuery(
    [...QUERY_KEYS.GET_ABOUT_HEADING, params.tournament],
    () => getAboutUsHeading(params),
    {
      enabled: isEnabled,
    }
  );
};

export const useAllTournaments = (params) => {
  return useQuery([...QUERY_KEYS.GET_ALL_TOURNAMENTS], getAllTournaments);
};

export const useParticipantsDetails = (params) => {
  return useQuery([...QUERY_KEYS.GET_PARTICPANTS_DETAILS, params.match], () =>
    getParticipants(params)
  );
};
export const useHomePageSportsList = () => {
  return useQuery([...QUERY_KEYS.GET_LANDING_PAGE_SPORTS_LIST, 'landing-page-sports-list'], () =>
    getHomePageSportsList()
  );
};
export const useHomePageNewsList = () => {
  return useQuery([...QUERY_KEYS.GET_LANDING_PAGE_NEWS_LIST, 'landing-page-news-list'], () =>
    getHomePageNewsList()
  );
};

export const useHomePageLiveMatchVideos = () => {
  return useQuery([...QUERY_KEYS.GET_LANDING_PAGE_LIVE_MATCH_VIDEOS, 'landing-page-live-match-videos'], () =>
    getHomePageLiveMatchVideos()
  );
};
